// xxx.js 葡萄牙文件
export default {
    app: {
        button:{
            logout: 'Sair',
            submit: 'Enviar',
            confirm: 'Cancelar',
            cancel: 'cancelamento',
            forbidden:'Proibido',
            enable:'Habilitar',
            dialog_confirm_delete_title:'Confirmar para excluir？',
            userpopover_delete_now:'Excluir imediatamente',
            userpopover_change_password:'Modificar senha',
            userpopover_delete_user:'Excluir usuário',
            userpopover_forbidden:'Usuário disponível',
        },
        title: 'Sistema de Gestão ADS',
        home: 'Casa',
        homenotic: 'Tem um lote de equipamento que está prestes a expirar, por favor contacte o comerciante se continuar a utilizá-lo! Os equipamentos expirados são:',
        user_manage: 'Usuário',
        mac_manage: 'Dispositivo',
        ad_manage: 'ANÚNCIOS',
        fie_manage: 'Arquivo',
        refresh_loosing_text: 'Libere para atualizar',
        refresh_loading_text: 'Enviando...',
        required_username: 'Nome de usuário de entrada',
        min_username: 'Por favor, insira um nome de usuário com mais de {t} caracteres',
        placeholder_username: 'Nome de usuário',
        add_username: 'Adicionar usuário',
        required_password: 'Por favor, insira a senha',
        min_password: 'Por favor, insira uma senha com mais de {t} caracteres',
        placeholder_password: 'Senha',
        required_confirm_password: 'Por favor, insira a senha',
        placeholder_confirm_password: 'Confirmar',
        two_password:'Senha diferente.',
        edit_password:'Editar senha',
        required_yzm: 'Por favor, preencha o código de verificação',
        placeholder_yzm: 'Código de verificação',
        user_count: 'Estatísticas do usuário',
        mac_count: 'Estatísticas do dispositivo',
        ad_count: 'Exibir estatísticas do AD',
        resources_count: 'Estatísticas de recursos',
        create_time:'Data de criação',
        not_more:'não_mais',
        search:'pesquisa',
        search_user:'Inserir nome de usuário',
        search_mac:'Por favor, insira o número Mac do dispositivo.',
        checkbox_select_max:'Já o maior',
        userpopover_delete_user_confirm:'Confirmar para excluir({u})?',
        userpopover_placeholder_forbidden_note:'Por favor, insira um comentário',
        user_list_button_dialog_title:'Controles de botões',
        user_list_button_dialog_note:'Queres mudar o interruptor?',
        ad_list_tab_list_total:'Total encontrado {t} AD',
        ad_list_tab_list_card_title:'Total de {m} vídeos e {i} imagens',
        ad_list_tab_list_card_type:'Tipo:{t}',
        ad_list_tab_list_card_play_type:'Modo de jogo：{t} {y}',
        ad_list_tab_list_card_mac:'Total {t} Dispositivo',
        ad_list_tab_list_card_day:'Data de lançamento:{t}',
        ad_show:'Conteúdo diferente em tela dupla',
        ad_not_show:'Mesmo conteúdo em tela dupla',
        ad_host_ping:'Tela 1',
        ad_deputy_ping:'Tela 2',
        ad_complete_ping:'Tela inteira',
        ad_two_screens:'Duas telas divididas',
        ad_three_screen:'Três telas divididas',
        ad_divide_ping:'Tela dividida',
        ad_class_ping_vertical:'Tela vertical',
        ad_class_ping_horizontal:'Paisagem",',
        ad_sort:'Classificar lista de reprodução',
        file_del:'Excluir',
        file_tab_type_1:'Tela cheia (imagem)',
        file_tab_type_2:'Vídeo',
        file_tab_type_3:'Foto',
        file_tab_vertical_type_3:'Três telas divididas (imagem acima)',
        file_tab_vertical_type_4:'Três telas divididas (imagem abaixo)',
        file_tab_vertical_type_5:'Imagem em dois ecrãs (em baixo)',
        file_tab_horizontal_type_3:'Três telas divididas (imagem à esquerda)',
        file_tab_horizontal_type_4:'Três telas divididas (imagem direita)',
        file_upload:'Carregar arquivo',
        file_upload_user:'Carregar usuário',
        file_upload_ing:'Enviando...',
        file_upload_success:'Carregar com sucesso',
        file_upload_fail:'Falha no upload',
        file_upload_confirm:'Confirmar upload',
        file_upload_max_count:'Carregar máximo de {t} arquivos de cada vez',
        file_upload_size:'Cada arquivo no máximo {t}M',
        file_upload_pixel_size:'Tamanho recomendado:',
        file_upload_format:'Formato do arquivo:{t}',
        file_upload_format_error:'Por favor, carregue arquivos no formato {t}',
        file_upload_filename:'Nomes de arquivos não podem ter símbolos especiais',
        file_release:'Liberar arquivo',
        file_release_select:'Escolher arquivo',
        file_release_select_num:'Total selecionado {t}',
        file_release_form_team_id_label:'Tipo de tela',
        file_release_form_ping_label:'Selecionar tela',
        file_release_form_palytype_label:'Modo de reprodução',
        file_release_form_display_true:'Sim',
        file_release_form_display_false:'Não',
        file_release_form_button_add_video:'Adicionar vídeo',
        file_release_form_button_add_img:'Adicionar foto',
        file_release_form_button_add:'Adicionar arquivos',
        file_release_form_placeholder_video:'Selecione um vídeo',
        file_release_form_placeholder_img:'Selecione uma imagem',
        file_release_confirm:'Confirmar para liberação',
        mac_confirm_batch_delete:'Excluir',
        mac_confirm_batch_replace_user:'Substituir usuário',
        mac_confirm_batch_set_up_expire:'Definir data válida',
        mac_title_click_select_user:'Selecionar usuário',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'On-line',
        mac_list_mac_status_lx:'Off-line',
        mac_list_mac_load_status_done:'Baixar com sucesso',
        mac_list_mac_load_status_waiting:'Aguardando download',
        mac_list_mac_expire:'Expirado',
        mac_list_mac_expire_time:'prazo de validade',
        mac_list_mac_update_user:'Operador de atualização',
        mac_list_export_name:'Exportação MAC do dispositivo {t}.xls',
        mac_import_template_name:'Modelo de dispositivos de importação em lote.xls',
        mac_import_template_button_name:'Adicionar dispositivo em lote',
        mac_menu_button_clean:'Limpar',
        mac_menu_button_batch_delete:'Excluir',
        mac_menu_button_batch_replace_user:'Substituir usuários',
        mac_menu_button_batch_replace_user_title:'Mover para o usuário',
        mac_menu_button_download_template:'Baixar modelo',
        mac_menu_button_set_expire:'Definir data válida',
        mac_menu_button_set_expire_select_user:'Selecionar usuário',
        mac_menu_button_set_expire_select_device:'Selecionar dispositivo',
        mac_menu_button_set_expire_select_day:'Selecionar data',
    }
}