// xxx.js 阿拉伯文件
export default {
    app: {
        button:{
            logout: 'خروج',
            submit: 'يُقدِّم',
            confirm: 'يتأكد',
            cancel: 'يلغي',
            forbidden:'مُحرَّم',
            enable:'يُمكَِن',
            dialog_confirm_delete_title:'تأكيد للحذف？',
            userpopover_delete_now:'حذف فورا',
            userpopover_change_password:'تعديل كلمة المرور',
            userpopover_delete_user:'مسح المستخدم',
            userpopover_forbidden:'تعطيل المستخدم',
        },
        title: 'نظام إدارة الإعلانات',
        home: 'بيت',
        homenotic: 'سوف تكون أجهزة منتهية الصلاحية الكمية',
        user_manage: 'مستخدم',
        mac_manage: 'جهاز',
        ad_manage: 'إعلانات',
        fie_manage: 'ملف',
        refresh_loosing_text: 'الافراج عن التحديث',
        refresh_loading_text: 'جارٍ التحميل',
        required_username: 'إدخال اسم المستخدم',
        min_username: 'الرجاء إدخال اسم مستخدم يحتوي على أكثر من{t}أحرف ',
        placeholder_username: 'اسم المستخدم',
        add_username: 'إضافة مستخدم',
        required_password: 'يرجى إدخال كلمة السر',
        min_password: 'الرجاء إدخال كلمة مرورمكونة من أكثر من{t}أحرف',
        placeholder_password: 'كلمة المرور',
        required_confirm_password: 'يرجى إدخال كلمة السر',
        placeholder_confirm_password: 'يتأكد',
        two_password:'كلمة المرور مختلفة',
        edit_password:'تحرير كلمة المرور',
        required_yzm: 'يرجى ملء رمز التحقق',
        placeholder_yzm: 'رمز التحقق',
        user_count: 'إحصائيات المستخدم',
        mac_count: 'إحصائيات الجهاز',
        ad_count: 'عرض إحصائيات الإعلان',
        resources_count: 'إحصائيات الموارد',
        create_time:'تاريخ الإنشاء',
        not_more:'لا أكثر',
        search:'الرجاء إدخال كلمة رئيسية',
        search_user:'إدخال اسم المستخدم',
        search_mac:'الرجاء إدخال رقم جهاز Mac',
        checkbox_select_max:'الأكبر بالفعل',
        userpopover_delete_user_confirm:'تأكيد الحذف({u})?',
        userpopover_placeholder_forbidden_note:'الرجاء إدخال ملاحظة',
        user_list_button_dialog_title:'زر التحكم',
        user_list_button_dialog_note:'إذا كان التبديل?',
        ad_list_tab_list_total:'إجمالي ما تم العثور عليه {t} م',
        ad_list_tab_list_card_title:'إجمالي {m} فيديو و{i} صورة',
        ad_list_tab_list_card_type:'النوع: {t}',
        ad_list_tab_list_card_play_type:'وضع التشغيل:{t} {y}',
        ad_list_tab_list_card_mac:'إجمالي {t} الجهاز',
        ad_list_tab_list_card_day:'تاريخ الإصدار:{t}',
        ad_show:'شاشة مزدوجة محتويات مختلفة',
        ad_not_show:'شاشة مزدوجة نفس المحتويات',
        ad_host_ping:'الشاشة 1',
        ad_deputy_ping:'الشاشة 2',
        ad_complete_ping:'تكبير الشاشة',
        ad_two_screens:'شاشتان منقسمتان',
        ad_three_screen:'ثلاث شاشات منقسمة',
        ad_divide_ping:'تقسيم الشاشة',
        ad_class_ping_vertical:'شاشة عمودية',
        ad_class_ping_horizontal:'شاشة أفقية',
        ad_sort:'فرز قائمة التشغيل',
        file_del:'يمسح',
        file_tab_type_1:'ملء الشاشة (صورة)',
        file_tab_type_2:'فيديو',
        file_tab_type_3:'صورة',
        file_tab_vertical_type_3:'ثلاث شاشات منقسمة (الصورة أعلاه)',
        file_tab_vertical_type_4:'ثلاث شاشات منقسمة (الصورة أدناه)',
        file_tab_vertical_type_5:'شاشتان منقسمتان (الصورة أدناه)',
        file_tab_horizontal_type_3:'ثلاث شاشات منقسمة (الصورة اليسرى)',
        file_tab_horizontal_type_4:'ثلاث شاشات منقسمة (الصورة اليمنى)',
        file_upload:'رفع ملف',
        file_upload_user:'تحميل المستخدم',
        file_upload_ing:'جارٍ التحميل...',
        file_upload_success:'تم التحميل بنجاح',
        file_upload_fail:'التحميل فشل',
        file_upload_confirm:'تأكيد التحميل',
        file_upload_max_count:'قم بتحميل ملف بحد أقصى {t} في كل مرة',
        file_upload_size:'كل ملف بحد أقصى {t}M',
        file_upload_pixel_size:'الحجم الموصى به：',
        file_upload_format:'تنسيق الملف:{t}',
        file_upload_format_error:'يرجى تحميل الملفات بتنسيق {t}.',
        file_upload_filename:'لا يمكن أن تحتوي أسماء الملفات على رموز خاصة！',
        file_release:'الافراج عن الملف',
        file_release_select:'اختر ملف',
        file_release_select_num:'الإجمالي المحدد {t}',
        file_release_form_team_id_label:'نوع الشاشة',
        file_release_form_ping_label:'حدد الشاشة',
        file_release_form_palytype_label:'طريقة اللعب',
        file_release_form_display_true:'نعم',
        file_release_form_display_false:'لا',
        file_release_form_button_add_video:'أضف فيديو',
        file_release_form_button_add_img:'إضافة صورة',
        file_release_form_button_add:'إضافة ملفات',
        file_release_form_placeholder_video:'الرجاء تحديد فيديو',
        file_release_form_placeholder_img:'الرجاء تحديد صورة',
        file_release_confirm:'تأكيد للإفراج',
        mac_confirm_batch_delete:'يمسح',
        mac_confirm_batch_replace_user:'استبدال المستخدم',
        mac_confirm_batch_set_up_expire:'قم بتعيين تاريخ صالح',
        mac_title_click_select_user:'اختر المستخدم',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'متصل',
        mac_list_mac_status_lx:'غير متصل على الانترنت',
        mac_list_mac_load_status_done:'تم التنزيل بنجاح',
        mac_list_mac_load_status_waiting:'في انتظار التحميل',
        mac_list_mac_expire:'منتهي الصلاحية',
        mac_list_mac_expire_time:'تاريخ انتهاء الصلاحية',
        mac_list_mac_update_user:'تحديث المشغل',
        mac_list_export_name:'تصدير جهاز MAC {t}.xls',
        mac_import_template_name:'قالب دفعة استيراد Devices.xls',
        mac_import_template_button_name:'دفعة إضافة جهاز',
        mac_menu_button_clean:'وضح',
        mac_menu_button_batch_delete:'يمسح',
        mac_menu_button_batch_replace_user:'استبدال المستخدمين',
        mac_menu_button_batch_replace_user_title:'انتقل إلى المستخدم',
        mac_menu_button_download_template:'تحميل القالب',
        mac_menu_button_set_expire:'قم بتعيين تاريخ صالح',
        mac_menu_button_set_expire_select_user:'اختر المستخدم',
        mac_menu_button_set_expire_select_device:'اختر جهاز',
        mac_menu_button_set_expire_select_day:'حدد تاريخ',
    }
}