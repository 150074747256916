// de.js 德语文件
export default {
    app: {
        button:{
            logout: 'Logout',
            submit: 'Änderung speichern',
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            forbidden:'Verbieten',
            enable:'Aktivieren',
            dialog_confirm_delete_title:'Löschen bestätigen？',
            userpopover_delete_now:'Jetzt löschen',
            userpopover_change_password:'Kennwort ändern',
            userpopover_delete_user:'Benutzer löschen',
            userpopover_forbidden:'Verbieten',
        },
        title: 'Werbemanagement System',
        home: 'Startseite',
        homenotic: 'Sie haben eine Charge von Geräten, die demnächst ablaufen. Wenn Sie diese weiterhin verwenden, wenden Sie sich bitte an den Händler! Abgelaufene Ausrüstung hat：',
        user_manage: 'Benutzer',
        mac_manage: 'Geräte',
        ad_manage: 'Playlist',
        fie_manage: 'Dateien',
        refresh_loosing_text: 'Aktualisierung...',
        refresh_loading_text: 'Wird geladen...',
        required_username: 'Benutzer eingeben',
        min_username: 'Bitte einen {t} stelligen Nutzer eingeben Zahlen oder Buchstaben',
        placeholder_username: 'Benutzername',
        add_username: 'Benutzer hinzufügen',
        required_password: 'Bitte geben Sie das Passwort ein',
        min_password: 'Bitte numerisches Passwort eingeben {t} Zeichen',
        placeholder_password: 'Passwort vergeben',
        required_confirm_password: 'Bitte geben Sie das Bestätigungspasswort ein',
        placeholder_confirm_password: 'Neues Passwort wiederholen',
        two_password:'Fehler, Passwörter stimmen nicht überein',
        edit_password:'Passwort ändern',
        required_yzm: 'Bitte geben Sie den Bestätigungscode ein',
        placeholder_yzm: 'Bestätigungscode',
        user_count: 'Benutzerstatistiken',
        mac_count: 'Gerätestatistiken',
        ad_count: 'Werbestatistiken',
        resources_count: 'Dateistatistiken',
        create_time:'Anlagedatum',
        not_more:'Listenende',
        search:'Geben Sie Suchbegriffe ein',
        search_user:'Benutzer eingeben',
        search_mac:'Nach MAC Adresse suchen',
        checkbox_select_max:'Das Maximum wurde erreicht',
        userpopover_delete_user_confirm:'Löschen bestätigen ({u}) Benutzer?',
        userpopover_placeholder_forbidden_note:'Bitte geben Sie deaktivierende Bemerkungen ein',
        user_list_button_dialog_title:'Tastensteuerungen',
        user_list_button_dialog_note:'Willst du den Schalter wechseln?',
        ad_list_tab_list_total:'Ingesamt {t} Dateien gefunden',
        ad_list_tab_list_card_title:'Insgesamt {m} Videos，{i} Bild',
        ad_list_tab_list_card_type:'Typ：{t}',
        ad_list_tab_list_card_play_type:'Anzeigemodus：{t} {y}',
        ad_list_tab_list_card_mac:'Auf Geräten freigegeben：{t}',
        ad_list_tab_list_card_day:'Veröffentlichung：{t}',
        ad_show:'Doppelbildschirme mit unterschiedlichen Inhalten',
        ad_not_show:'Doppelbildschirme mit gleichem Inhalt',
        ad_host_ping:'Bildschirm 1',
        ad_deputy_ping:'Bildschirm 2',
        ad_complete_ping:'Full Screen',
        ad_two_screens:'Double Split',
        ad_three_screen:'Triple Split',
        ad_divide_ping:'Double Split',
        ad_class_ping_vertical:'Hochformat',
        ad_class_ping_horizontal:'Querformat',
        ad_sort:'Sortierung',
        file_del:'Löschen',
        file_tab_type_1:'Bild - Full Screen',
        file_tab_type_2:'Video - Full Screen und Split Screen',
        file_tab_type_3:'Bild(er)',
        file_tab_vertical_type_3:'Triple Split - Bild oben',
        file_tab_vertical_type_4:'Triple Split - Bild unten',
        file_tab_vertical_type_5:'Double Split - Bild unten',
        file_tab_horizontal_type_3:'Tripple Split Screen (linkes Bild)',
        file_tab_horizontal_type_4:'Tripple Split Screen (rechtes Bild)',
        file_upload:'Datei hochladen',
        file_upload_user:'Benutzer hochladen',
        file_upload_ing:'Hochladen...',
        file_upload_success:'Upload erfolgreich',
        file_upload_fail:'Upload fehlgeschlagen',
        file_upload_confirm:'Upload bestätigen',
        file_upload_max_count:'Es können nicht mehr als {t} Dateien gleichzeitig hochgeladen werden',
        file_upload_size:'Dateigröße:maximal {t}MB',
        file_upload_pixel_size:'Empfohlene Größe：',
        file_upload_format:'Dateiformat:{t}',
        file_upload_format_error:'Bitte laden Sie eine Datei im {t} Format hoch',
        file_upload_filename:'Beachten: Keine Dateinamen mit Sonderzeichen möglich',
        file_release:'Datei freigeben',
        file_release_select:'Wählen Sie ein Dokument aus',
        file_release_select_num:'Gesamt ausgewählt: {t}',
        file_release_form_team_id_label:'Bildschirmtyp',
        file_release_form_ping_label:'Bildschirm wählen',
        file_release_form_palytype_label:'Anzeigemodus',
        file_release_form_display_true:'Ja',
        file_release_form_display_false:'Nein',
        file_release_form_button_add_video:'Video hinzufügen',
        file_release_form_button_add_img:'Bild hinzufügen',
        file_release_form_button_add:'Dateien hinzufügen',
        file_release_form_placeholder_video:'Bitte Videodatei auswählen',
        file_release_form_placeholder_img:'Bitte Bilddatei auswählen',
        file_release_confirm:'Freigabe bestätigen',
        mac_confirm_batch_delete:'Löschen',
        mac_confirm_batch_replace_user:'Ersetzen Sie Benutzer',
        mac_confirm_batch_set_up_expire:'Set Valid Date',
        mac_title_click_select_user:'Klicken Sie, um den Benutzer auszuwählen',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'Online',
        mac_list_mac_status_lx:'Offline',
        mac_list_mac_load_status_done:'Download abgeschlossen',
        mac_list_mac_load_status_waiting:'Warten auf Download',
        mac_list_mac_expire:'abgelaufen sein',
        mac_list_mac_expire_time:'Ablaufdatum',
        mac_list_mac_update_user:'Aktueller Benutzer',
        mac_list_export_name:'Geräte-MAC-Export {t}.xls',
        mac_import_template_name:'Importvorlage für Geräte.xls',
        mac_import_template_button_name:'Geräteliste hochladen',
        mac_menu_button_clean:'Bereinigen',
        mac_menu_button_batch_delete:'Löschen',
        mac_menu_button_batch_replace_user:'Ersetzen Sie Benutzer',
        mac_menu_button_batch_replace_user_title:'Neuen Benutzer wählen',
        mac_menu_button_download_template:'Template herunterladen',
        mac_menu_button_set_expire:'Set Valid Date',
        mac_menu_button_set_expire_select_user:'Benutzer auswählen',
        mac_menu_button_set_expire_select_device:'Gerät auswählen',
        mac_menu_button_set_expire_select_day:'Datum auswählen',
    }
}