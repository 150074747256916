// zh_cn.js 中文文件
export default {
    app: {
        button:{
            logout: '退出登录',
            submit: '提交',
            confirm: '确认',
            cancel: '取消',
            forbidden:'禁用',
            enable:'启用',
            dialog_confirm_delete_title:'是否确认删除？',
            userpopover_delete_now:'立即删除',
            userpopover_change_password:'修改密码',
            userpopover_delete_user:'删除用户',
            userpopover_forbidden:'禁用',
        },
        title: '广告管理系统',
        home: '首页',
        homenotic: '您有一批设备即将到期，如继续使用请联系商家！到期设备有：',
        user_manage: '用户管理',
        mac_manage: '设备管理',
        ad_manage: '广告管理',
        fie_manage: '文件管理',
        refresh_loosing_text: '释放即可刷新...',
        refresh_loading_text: '加载中...',
        required_username: '请填写用户名',
        min_username: '请输入{t}位以上的用户名',
        placeholder_username: '用户名',
        add_username: '新增用户',
        required_password: '请填写密码',
        min_password: '请输入{t}位以上的密码',
        placeholder_password: '密码',
        required_confirm_password: '请填写确认密码',
        placeholder_confirm_password: '确认密码',
        two_password:'两次密码不一致',
        edit_password:'编辑密码',
        required_yzm: '请填写验证码',
        placeholder_yzm: '验证码',
        user_count: '用户统计',
        mac_count: '设备统计',
        ad_count: '广告统计',
        resources_count: '资源统计',
        create_time:'创建时间',
        not_more:'没有更多了',
        search:'请输入搜索关键词',
        search_user:'请输入搜索账号',
        search_mac:'请输入搜索MAC地址',
        checkbox_select_max:'已达最大',
        userpopover_delete_user_confirm:'是否确认删除({u})用户?',
        userpopover_placeholder_forbidden_note:'请输入禁用备注',
        user_list_button_dialog_title:'按钮控件',
        user_list_button_dialog_note:'是否切换开关?',
        ad_list_tab_list_total:'共找到{t}个广告',
        ad_list_tab_list_card_title:'共{m}个视频，{i}张图片',
        ad_list_tab_list_card_type:'类型：{t}',
        ad_list_tab_list_card_play_type:'播放方式：{t} {y}',
        ad_list_tab_list_card_mac:'广告机：共投放{t}台',
        ad_list_tab_list_card_day:'发布日期：{t}',
        ad_show:'支持异显',
        ad_not_show:'不支持异显',
        ad_host_ping:'主屏',
        ad_deputy_ping:'副屏',
        ad_complete_ping:'全屏',
        ad_two_screens:'两屏',
        ad_three_screen:'三屏',
        ad_divide_ping:'分屏',
        ad_class_ping_vertical:'竖屏',
        ad_class_ping_horizontal:'横屏',
        ad_sort:'排序',
        file_del:'批量删除',
        file_tab_type_1:'全屏图片',
        file_tab_type_2:'视频',
        file_tab_type_3:'图片',
        file_tab_vertical_type_3:'三屏图片(上)',
        file_tab_vertical_type_4:'三屏图片(下)',
        file_tab_vertical_type_5:'两屏图片(下)',
        file_tab_horizontal_type_3:'三屏图片(左)',
        file_tab_horizontal_type_4:'三屏图片(右)',
        file_upload:'上传文件',
        file_upload_user:'上传用户',
        file_upload_ing:'上传中...',
        file_upload_success:'上传成功',
        file_upload_fail:'上传失败',
        file_upload_confirm:'确认上传',
        file_upload_max_count:'每次上传文件不能超过{t}个',
        file_upload_size:'文件大小要求：最大不超过{t}M',
        file_upload_pixel_size:'推荐尺寸：',
        file_upload_format:'文件格式：{t}',
        file_upload_format_error:'请上传 {t} 格式文件',
        file_upload_filename:'注意：文件名称不能有特殊符号！',
        file_release:'发布文件',
        file_release_select:'选择文件',
        file_release_select_num:'共选中 {t}',
        file_release_form_team_id_label:'屏幕类型',
        file_release_form_ping_label:'选择屏幕',
        file_release_form_palytype_label:'播放方式',
        file_release_form_display_true:'是',
        file_release_form_display_false:'否',
        file_release_form_button_add_video:'添加视频',
        file_release_form_button_add_img:'添加图片',
        file_release_form_button_add:'添加文件',
        file_release_form_placeholder_video:'请选择视频',
        file_release_form_placeholder_img:'请选择图片',
        file_release_confirm:'确认发布',
        mac_confirm_batch_delete:'批量删除',
        mac_confirm_batch_replace_user:'批量更换用户',
        mac_confirm_batch_set_up_expire:'设置有效期',
        mac_title_click_select_user:'点击选择用户',
        mac_list_mac_delete:'删除设备广告文件',
        mac_list_mac_status_zx:'在线',
        mac_list_mac_status_lx:'离线',
        mac_list_mac_load_status_done:'下载完成',
        mac_list_mac_load_status_waiting:'等待下载',
        mac_list_mac_expire:'已过期',
        mac_list_mac_expire_time:'过期时间',
        mac_list_mac_update_user:'更新操作人',
        mac_list_export_name:'设备的MAC导出 {t}.xls',
        mac_import_template_name:'设备批量导入模板.xls',
        mac_import_template_button_name:'批量导入设备',
        mac_menu_button_clean:'清除',
        mac_menu_button_batch_delete:'批量删除',
        mac_menu_button_batch_replace_user:'批量更换用户',
        mac_menu_button_batch_replace_user_title:'请选择移动至用户',
        mac_menu_button_download_template:'下载模板',
        mac_menu_button_set_expire:'设置有效期',
        mac_menu_button_set_expire_select_user:'选择用户',
        mac_menu_button_set_expire_select_device:'选择设备',
        mac_menu_button_set_expire_select_day:'选择日期',
    }
}