// xxx.js 俄语文件
export default {
    app: {
        button:{
            logout: 'Выйти',
            submit: 'Отправить',
            confirm: 'Подтвердить',
            cancel: 'Отменить',
            forbidden:'Запрещено',
            enable:'Включить',
            dialog_confirm_delete_title:'Подтвердить удаление?',
            userpopover_delete_now:'Удалить немедленно',
            userpopover_change_password:'Изменить пароль',
            userpopover_delete_user:'Удалить пользователя',
            userpopover_forbidden:'запрещать использование чего-л.',
        },
        title: 'Система управления ADS',
        home: 'Дом',
        homenotic: 'Кол-во устройств с истекшим сроком действия:',
        user_manage: 'Пользователь',
        mac_manage: 'Устройство',
        ad_manage: 'ADS',
        fie_manage: 'Файл',
        refresh_loosing_text: 'Отпустите для обновления...',
        refresh_loading_text: 'Загрузк...',
        required_username: 'Введите имя пользователя',
        min_username: 'Пожалуйста, введите имя пользователя, состоящее более чем из{t}символов',
        placeholder_username: 'Имя пользователя',
        add_username: 'Добавить пользователя',
        required_password: 'Пожалуйста, введите пароль',
        min_password: 'Пожалуйста, введите пароль длиной более{t}символов',
        placeholder_password: 'Пароль',
        required_confirm_password: 'Пожалуйста, введите пароль',
        placeholder_confirm_password: 'Подтвердить',
        two_password:'Пароль другой.',
        edit_password:'Изменить пароль',
        required_yzm: 'Пожалуйста, введите проверочный код',
        placeholder_yzm: 'Проверочный код',
        user_count: 'Статистика пользователя',
        mac_count: 'Статистика устройства',
        ad_count: 'Отображение статистики AD',
        resources_count: 'Статистика ресурсов',
        create_time:'Дата создания',
        not_more:'Больше нет',
        search:'Пожалуйста, введите ключевое слово',
        search_user:'Введите имя пользователя',
        search_mac:'Пожалуйста, введите Mac-номер устройства.',
        checkbox_select_max:'Уже самый большой',
        userpopover_delete_user_confirm:'Подтвердить удаление({u})?',
        userpopover_placeholder_forbidden_note:'Пожалуйста, введите примечание',
        user_list_button_dialog_title:'Кнопки',
        user_list_button_dialog_note:'Переключить или нет?',
        ad_list_tab_list_total:'Всего найдено {t} AD',
        ad_list_tab_list_card_title:'Всего видео: {m} и изображений: {i}',
        ad_list_tab_list_card_type:'Тип: {t}',
        ad_list_tab_list_card_play_type:'Режим воспроизведения:{t} {y}',
        ad_list_tab_list_card_mac:'Всего устройств: {t}',
        ad_list_tab_list_card_day:'Дата выпуска:{t}',
        ad_show:'Двойной экран, разное содержимое',
        ad_not_show:'Двойной экран с одинаковым содержанием',
        ad_host_ping:'Экран 1',
        ad_deputy_ping:'Экран 2',
        ad_complete_ping:'полный экран',
        ad_two_screens:'Два разделенных экрана',
        ad_three_screen:'Три разделенных экрана',
        ad_divide_ping:'Разделенный экран',
        ad_class_ping_vertical:'Вертикальный экран',
        ad_class_ping_horizontal:'Горизонтальный экран',
        ad_sort:'Сортировать плейлист',
        file_del:'Удалить',
        file_tab_type_1:'Полный экран (изображение)',
        file_tab_type_2:'Видео',
        file_tab_type_3:'Фото',
        file_tab_vertical_type_3:'Три разделенных экрана (изображение выше)',
        file_tab_vertical_type_4:'Три разделенных экрана (изображение ниже)',
        file_tab_vertical_type_5:'Два разделенных экрана (изображение ниже)',
        file_tab_horizontal_type_3:'Тройное изображение на экране (слева)',
        file_tab_horizontal_type_4:'Три разделенных экрана (правое изображение)',
        file_upload:'Загрузить файл',
        file_upload_user:'Загрузить пользователя',
        file_upload_ing:'Загрузка...',
        file_upload_success:'Загрузка прошла успешно',
        file_upload_fail:'Ошибка загрузки',
        file_upload_confirm:'Подтвердить загрузку',
        file_upload_max_count:'Загружать максимум {t} файлов каждый раз',
        file_upload_size:'Макс. каждого файла {t}M',
        file_upload_pixel_size:'Рекомендуемый размер:',
        file_upload_format:'Формат файла:{t}',
        file_upload_format_error:'Пожалуйста, загрузите файлы в формате {t}',
        file_upload_filename:'Примечание: имена файлов не могут содержать специальных символов!' ,
        file_release:'Публикация документов',
        file_release_select:'Выбрать файл',
        file_release_select_num:'Всего выбрано {t}',
        file_release_form_team_id_label:'Всего выбрано {t}',
        file_release_form_ping_label:'Выбрать экран',
        file_release_form_palytype_label:'Режим воспроизведения',
        file_release_form_display_true:'Да',
        file_release_form_display_false:'Нет',
        file_release_form_button_add_video:'Добавить видео',
        file_release_form_button_add_img:'Добавить фото',
        file_release_form_button_add:'Добавить файл',
        file_release_form_placeholder_video:'Пожалуйста, выберите видео',
        file_release_form_placeholder_img:'Пожалуйста, выберите изображение',
        file_release_confirm:'Подтвердить выпуск',
        mac_confirm_batch_delete:'Удалить',
        mac_confirm_batch_replace_user:'Заменить пользователя',
        mac_confirm_batch_set_up_expire:'Установить дату действия',
        mac_title_click_select_user:'Выбрать пользователя',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'Онлайн',
        mac_list_mac_status_lx:'Не в сети',
        mac_list_mac_load_status_done:'Загрузка прошла успешно',
        mac_list_mac_load_status_waiting:'Ожидание загрузки',
        mac_list_mac_expire:'Истек срок',
        mac_list_mac_expire_time:'Дата истечения срока действия',
        mac_list_mac_update_user:'Оператор обновления',
        mac_list_export_name:'Экспорт MAC устройства {t}.xls',
        mac_import_template_name:'Шаблон устройств пакетного импорта.xls',
        mac_import_template_button_name:'Пакетное добавление устройства',
        mac_menu_button_clean:'Очистить',
        mac_menu_button_batch_delete:'Удалить',
        mac_menu_button_batch_replace_user:'Заменить пользователей',
        mac_menu_button_batch_replace_user_title:'Пожалуйста, выберите переход к пользователю',
        mac_menu_button_download_template:'Загрузить шаблон',
        mac_menu_button_set_expire:'Установить дату истечения срока действия',
        mac_menu_button_set_expire_select_user:'ыбрать пользователя',
        mac_menu_button_set_expire_select_device:'Выбрать устройство',
        mac_menu_button_set_expire_select_day:'Выбрать день',
    }
}